<template>
  <section class="content-container">
    <SectionTabs :tabs="tab_component"/>
    <router-view/>
  </section>
</template>

<script>
import SectionTabs from "@/components/Section/SectionTabs.vue";

export default {
  components: { 
    SectionTabs,
  },
  data() {
    return {
       title: "Organigrama",
      tab_component: [
        {
          name: "Lista",
          route: "org-list",
        },
        {
          name: "Agregar",
          route: "org-add",
        },
         {
          name: "Empresas",
          route: "org-empresas",
        },
      ],
    };
  }
};
</script>